<template>
  <div class="page-reviews-products">
    <back />
    <page-title title="Отзывы" description="Здесь отображаются отзывы на этот товар" />
    <n-loader :loading="$var('load')" />

    <n-slider v-if="!$var('load')" :data="product.images" :options="imagesOptions" pagination class="images">
      <template #item="{item}">
        <n-link :to="{name: 'products.photos', params: {id: product.id, photoId: item.id},}"></n-link>
        <img :src="item.src" alt="image">
      </template>
    </n-slider>

    <div class="container">
      <div class="wrap">
        <div class="title-wrap">
          <h2>{{ product.title }}</h2>
          <div class="price">{{ $n.numberFormat(product.cost) }} ₸</div>
        </div>

        <div class="reviews">
          <div>
            <star-rating read-only :star-size="15" :rating="product.storeId ? product.rating : product.author.rating" :increment="0.01" />
          </div>
        </div>
      </div>

      <div class="content">
        <n-loader :loading="$var('loadReview')" />
        <review :reviews="reviews" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageReviewsProducts',
  data() {
    return {
      imagesOptions: {
        loop: false,
        centeredSlides: true,
      },
      product: {},
      reviews: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      const apis = [
        $api.app.products.reviews(this.id).with('author'),
        $api.app.products.get(this.id).with('images').with('author'),
      ]
      Promise.all(apis).then((responses)=>{
        this.reviews = responses[0].data.content
        this.product = responses[1].data.content
      }).finally(()=>{
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-reviews-products {
  &::v-deep {
    .page-title {
      margin-bottom: 0;
    }
  }

  .images {
    margin-bottom: 25px;
    min-height: 400px;

    &::v-deep .swiper-slide {
      height: 400px;
      overflow: hidden;
      position: relative;
      img { width: 100%; height: 100%; object-fit: cover; }
      a { position: absolute; top: 0; bottom: 0; right: 0; left: 0; }
    }
  }

  .title-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 6px;

    .price {
      white-space: nowrap;
    }
  }

  .wrap {
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 8px;
    margin-bottom: 25px;
  }

  .content {
    position: relative;
  }
}
</style>

